import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';

class NotExist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sent: false,
            buttonText: "Request Token"
        };
    }

    render() {
        return (
            <Grid>
                <Grid.Row centered>
                    <h1>Page Does Not Exist</h1>
                </Grid.Row>
                <Grid.Row centered>
                    <div className="box white normal">
                        <p>Page that you requested does not exist.</p>
                    </div>
                </Grid.Row>
            </Grid>
        );
    }
}

export default NotExist;
