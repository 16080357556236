import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';
import '../App.css';
import GoHome from "../components/GoHome";

class UseMarket extends Component {
  render() {
    return (
      <Grid>
        <Link to='/addaccount'>
            <Icon style="go-back animate" icon="Back">
                How To Add Account
            </Icon>
        </Link>
        <Link to='/createstrategy'>
            <Icon style="go-front animate" icon="Next">
                How To Create Strategy
            </Icon>
        </Link>
        <Grid.Row centered>
            <h1> How To Use Market </h1>
            <div className="box white normal">
            <p> The Market in the CryptoLooper is more of a “Why not” feature, it was minimum effort to actually set it up yet it has been proven to be very useful. The market is there for you to check out the current situation of currencies and make immediate trades between them. It’s just nice to have it at hand so you don’t have to go and login into the exchange's website in order to trade. </p>
            <p> Market lets you trade just between currencies that the particular exchange support direct exchanges. Put in how much you want to trade, see the estimate you get for it and click “Trade”. As simple as that. </p>
            </div>
        </Grid.Row>
          <GoHome />
      </Grid>
    );
  }
}

export default UseMarket;
