import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';
import '../App.css';
import GoHome from "../components/GoHome";

class RunStrategy extends Component {
  render() {
    return (
      <Grid>
        <Link to='/createstrategy'>
            <Icon style="go-back animate" icon="Back">
                How To Create Strategy
            </Icon>
        </Link>
        <Link to='/'>
            <Icon style="go-front animate" icon="Next">
                Homepage
            </Icon>
        </Link>
        <Grid.Row centered>
            <h1> How To Run Strategy </h1>
            <div className="box white normal">
            <p>To make sure your strategy is properly tested out we provided the option of Dry Run, where the CryptoLooper follows the exchange and simulate the behavior of the strategy without making any actual trades. In order to speed thing up, you can choose a specific time frame to run your strategy on historical data the exchange provides. For a more authentic experience, you can just let it run on current incoming data and be either glad it was just a simulation or regret missing out on a profit. </p>

            <p>It is completely up to you how soon you want to run it for real, us at CryptoLooper sometimes let the strategy run for two days before we let it run wild, but hey, that’s just us. Bear in mind we are not responsible if you define your strategy incorrectly and some kind of scenario you didn’t plan for throws it for a loop.</p>

            <p>In either of the Runs, just select the strategy you want to run and click Play button (&#9658;). Once the strategy is running you’ll get a notification in log whenever any of your strategy’s triggers gets fired, you can also watch simultaneously running graph for more information. The Pause button (&#10073; &#10073;) does just that, it pauses the strategy and in case you press Play it resumes right there where it left off. In case you decide to Stop (&#9724;) the strategy, CryptoLooper trades everything back to original currency and return the strategy to its initial state. Since this can be quite destructive, we recommend pausing the strategy first and after reevaluating everything is as it should hit the stop button.</p>
            </div>
        </Grid.Row>
          <GoHome />
      </Grid>
    );
  }
}
export default RunStrategy;
