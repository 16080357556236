import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from './img/logo.svg';
import screen1 from "./img/screen1.jpg";
import './App.css';
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";

class App extends Component {
  render() {
    return (
      <Grid>
        <div className="corner-ribbon">No coding needed</div>

        <Grid.Row centered>
            <Grid.Column width={10} mobile={14}>
                <div className="banner">
                    <img src={logo} className="logo" alt="logo" />
                    <h1>CryptoLooper</h1>
                </div>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <div className="box white">
            <p className="justify">
                CryptoLooper is a tool that lets you create <strong>independently</strong> running strategies at crypto-currency exchanges without a need for any coding whatsoever. With an intuitive interface, you can add your account to the application, do simple one time exchanges, create as simple or as complex <strong>strategies</strong> you want and then let them run wild. The tool will not only help you <strong>visualize</strong> the strategies you are crafting but also lets you test on any past data the exchanges offer. Unlike other many other tools, CryptoLooper respects your privacy, <strong>does not store</strong> your <strong>trading keys</strong> outside your computer, and never takes any percentage of your trade profit what so ever.
            </p>
            <p>
                <div className="buttons">
                    <Link to="/download">
                        <Button size={"huge"} primary>
                            <Icon name="download" /> Download Free Alpha
                        </Button>
                    </Link>
                </div>
            </p>
            </div>
        </Grid.Row>
        <Grid.Row centered id="top">
        <a href="#top">
            <div className="scroll-downs">
                  <div className="mousey">
                    <div className="scroller"/>
                  </div>
            </div>
        </a>
        </Grid.Row>
        <Grid.Row centered style={{'margin-top': '20vh'}}>
            <Grid.Column width={9}>
                <div class="img-box">
                    <img className={'screen'} src={screen1} />
                </div>
            </Grid.Column>
            <Grid.Column width={7}>
                <h2>Tutorials</h2>
                <p className="list"><Link to='/addaccount'>Adding Account</Link></p>
                <p className="list"><Link to='/usemarket'>Market Trading</Link></p>
                <p className="list"><Link to='/createstrategy'>Creating Strategy</Link></p>
                <p className="list"><Link to='/runstrategy'>Running Strategy</Link></p>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <div class="box white">
                <h2> FAQ </h2>
                <div className="question">
                <input className="show-more-cb" type="checkbox" id="1" />
                <label for="1"> <p> Is my account safe? </p> </label>

                <p className="show-more">
                    We designed the application with the safety on our mind, we are dealing with money here after all. If your using the CryptoLooper desktop application, your trading keys are stored on your computer and with no outside access. In order to put your mind at ease, we however still recommend using the key that disallows crypto currency withdrawal.<br /><br />
                </p>
                </div>
                <div className="question">
                <input className="show-more-cb" type="checkbox" id="2" />
                <label for="2"> <p> The strategy I want cannot be created in the application, what should I do? </p> </label>
                <p className="show-more">
                    We try to make the setting of the strategies as flexible as possible, but we also don’t want to overwhelm the user with unnecessary settings. Nevertheless, we would still love to hear from you what is it you are missing: <a href="mailto:info@cryptolooper.com">info@cryptolooper.com</a>. If we deem it useful for larger user base we will add it as soon as possible. In case we don’t, we are planning to add “advanced setting” to next version when we can add all sort of stuff without breaking our principles of apps simplicity. <br /><br />
                </p>
                </div>
                <div className="question">
                <input className="show-more-cb" type="checkbox" id="3" />
                <label for="3"> <p> Can I try out my strategies without using money? </p> </label>
                <p className="show-more">
                    Yes, you can, you can read about it more here. TL:DR: you can run it on any old data provided by exchanges or you can do a “Dry run”, that behaves the same as an actual run without the money involved. <br /><br />
                </p>
                </div>
                <div className="question">
                <input className="show-more-cb" type="checkbox" id="4" />
                <label for="4"> <p> There is no option to trade between currencies I want, why? </p> </label>
                <p className="show-more">
                    As of now, we only support trades that can be directly made on a specific exchange. Every exchange has slightly different combination than between with currencies you can directly trade. <br /><br />
                </p>
                </div>
                <div className="question">
                <input className="show-more-cb" type="checkbox" id="6" />
                <label for="6"> <p> Is there a way to export strategy to share? </p> </label>
                <p className="show-more">
                    Well, you can always export your strategy from the strategy screen of the application.<br /><br />
                </p>
                </div>
                <p> Do you have any other questions? Please contact us at <a href="mailto:info@cryptolooper.com">info@cryptolooper.com</a>. </p>
            </div>
        </Grid.Row>
      </Grid>
    );
  }
}

export default App;
