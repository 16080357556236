import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './index.css';
import App from './App';
import AddAccount from './howto/AddAccount';
import UseMarket from './howto/UseMarket';
import CreateStrategy from './howto/CreateStrategy';
import RunStrategy from './howto/RunStrategy';
import Download from './pages/Download';
import NotExist from './pages/NotExist';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
unregister();

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={App}/>
            <Route path='/addaccount' component={AddAccount}/>
            <Route path='/usemarket' component={UseMarket}/>
            <Route path='/createstrategy' component={CreateStrategy}/>
            <Route path='/runstrategy' component={RunStrategy}/>
            <Route path='/download' component={Download}/>
            <Route component={NotExist}/>
        </Switch>
    </BrowserRouter>, document.getElementById('root'));
// registerServiceWorker();
