import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Grid, Button, Icon } from 'semantic-ui-react';

class GoHome extends Component {
    render() {
        return (
            <Grid.Row centered>
                <Link to='/'>
                    <Button size="huge">
                        <Icon name="home" /> Go Back Home
                    </Button>
                </Link>
            </Grid.Row>);
    }
}

export default GoHome;
