import React, { Component } from 'react';
import {Grid, Header, Message} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';
import '../App.css';

import strategy1 from "../img/strategy1.jpg";
import strategy2 from "../img/strategy2.jpg";
import strategy3 from "../img/strategy3.jpg";
import GoHome from "../components/GoHome";

const SinceTransactionFallRaiseText = <p>Trigger is fired if the currency experiences specified percentage fall/raise in value since the <b>last purchase</b> of the currency.<br/><br/>
    min. 0% / max. 999% </p>;
const MomentumText = <p> Momentum not only takes in the consideration the tilt of the raise/fall of the currency, but also the time it took for the tilt to happen.<br/><br/>
                    &nbsp; &nbsp; &#x2934; 50%<br/>
                    &nbsp; &nbsp;  &#10549; -50%<br/>
                    &nbsp; &nbsp; &#x2192; 0%<br/><br/>
                min. value: -99% / max. value: 99% </p>;

class CreateStrategy extends Component {
  render() {
    return (
      <Grid>
        <Link to='/usemarket'>
            <Icon style="go-back animate" icon="Back">
                How To Use Market
            </Icon>
        </Link>
        <Link to='/runstrategy'>
            <Icon style="go-front animate" icon="Next">
                How To Run Strategy
            </Icon>
        </Link>
        <Grid.Row centered>
            <h1> How To Create Strategy </h1>
            <div className="box white normal">
                <Message>
                    <Header>
                        Disclaimer
                    </Header>
                    <p>
                        This isn’t a tutorial on how to make millions and billion, if we knew how to do that we wouldn't be having this conversation. This is solely to get you familiar with the process of reacting a strategy CryptoLooper.
                    </p>
                </Message>

            <p> Creating and using a strategy is one of the fundamental features you can take advantage of when using CryptoLooper. Strategy gives CryptoLooper the guidelines based on which it makes automatic trades between cryptocurrencies. In this HowTo we’ll help you get familiar with the structure of individual strategies and how simple it is to create your own.</p>

            <p> In CryptoLooper you can build as many strategies as you please (they're saved on your computer, so we don’t really care) and customize them to follow your every order. Create a new strategy, give it a name and if you are not “go for broke” type of person you want to start by giving your strategy a budget. No matter what the strategy will never touch more money than it’s given. Now that you have the basic description of the strategy set up, let’s start adding triggers.</p>

            <img className={'full-img'} src={strategy1} />

            <h2> Triggers </h2>
            <p> You can look at trigger as a conditions and when the condition get’s met, the trigger gets fired. Fired trigger can do three things, you buy, you sell or you do nothing (we’ll get to this one later). Trigger is a complex structure on its own, but thank to that it gives you the flexibility precisely specify what has to happen for your trigger to go of. Triggers work within the means of the budget, but you don’t have to use your entire budget, you can specify what part of budget you want to use. Let’s start with an example, if you want to sell 20% of your budget after it made 10% profit, you just use
            <div class="center-cyrcle">
            <Icon style="question-cyrcle" icon="Question">
                {SinceTransactionFallRaiseText}
            </Icon>
            Change Since Last Transaction
            </div>
            raise condition and specify 10% in the adjacent input slot and 20% in your trigger’s budget box. Once you put that in, you can check out the below graph to see when in the past the trigger would have gotten fired. Don’t like what you see? Would you like to wait a bit longer if the value keeps on growing? You can combine multiple conditions in one trigger, in this case we would want to add something like
            <div class="center-cyrcle">
            <Icon style="question-cyrcle" icon="Question">
                {MomentumText}
            </Icon>
            Momentum
            </div></p>

            <img className={'full-img'} src={strategy2} />

            <p>Selling is nice and if you just want a tool that makes sure you don’t miss out on the peak, we don’t see any problem using CryptoLooper just for that. But to make a more self-sufficient strategy you will probably want to buy as well. Applying similar logic explained previous you’ll have a complete strategy in no time.</p>

            <h2>States</h2>

            <p>The green state boxes inside triggers are not there just to add a splash of color but are extremely helpful if you want to take care of situation bit more delicately. When you leave everything in “initial” all the trigger can be triggered whenever their conditions are met. But picture this. You made your sale and made your 10%. But look at that, the value keeps on growing and it reaches another 10% growth, what a deal, and you want to take this opportunity to sell 50% of your budget not just 20%. To do so you must somehow memorize that you have already made that first sale and now you want to react differently to the same growth. Well, let's create a state. Let’s call it something intuitive like “Sold 20%” or “s20a10s1293b”. If the trigger gets fired and we make our first sale we move from our “initial” state to our brand new  “Sold 20%”. Now the only trigger that is currently in  “Sold 20%” can react. </p>

            <p>Let’s create a new trigger to take care of the previously mentioned situation. So another “?Since transaction raise” with expected 10% growth, but now we specified the portion of the budget is 50% and the beginning state to “Sold 20%”. But what should be your target state? Well, that’s up to you. You can specify another threshold in growth or you can return to your to your “initial” state.</p>

            <img className={'full-img'} src={strategy3} />

            <p>You might notice that we have been quite optimistic, that we always insinuated the value to grow. In expectation of value not doing so well you might want to have triggers taking care of not so pleasant scenarios.</p>

            <p>Ou and there is also a possibility to do nothing, just in case your strategy just wants to acknowledge something but not buy or sell at the moment.</p>

            <h2>Other bits and pieces</h2>
            <p>Don’t forget there is a number of ways to test your strategy out with actual data without doing the actual trades. So feel free to play around with the strategy and make sure it’s good to go into the real world of cryptocurrencies. </p>

            </div>
        </Grid.Row>
          <GoHome />
      </Grid>
    );
  }
}

export default CreateStrategy;
