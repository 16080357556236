import React, { Component } from 'react';
import {Grid, Header, Icon, Message, Container, Segment} from 'semantic-ui-react';
import GoHome from "../components/GoHome";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import * as validator from "email-validator";
import querystring from "querystring";
import fetch from "node-fetch";

class Download extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sent: false
        };
    }

    render() {
        const latest = "0.9.7";
        return (
            <Grid>
                <Grid.Row centered>
                    <h1>Download</h1>
                </Grid.Row>
                <Grid.Row centered>
                    <div className="box white normal">
                        {
                            this.state.sent ?
                            <Message>
                                <Header>
                                    Token Request Sent
                                </Header>
                                <p>
                                    Your request for token has been sent. Your token should be generated within 48hrs.
                                </p>
                            </Message> : ""
                        }

                        <p>Alpha version of CryptoLooper is now available in version for Windows and Mac. The Alpha version is free for everyone, however, in order to limit the amount of load at our servers, you will need to authenticate with an activation token. A token can obtained by filling your email address in the form below.</p>

                        <Grid stackable columns={3} centered>
                            <Grid.Column textAlign="center">
                                <Button size={"big"} primary onClick={() =>
                                    window.open(`https://files.cryptolooper.com/CryptoLooper-win-${latest}.exe`)}>
                                    <Icon name="windows" /> CryptoLooper For Windows
                                </Button>
                            </Grid.Column>

                            <Grid.Column textAlign="center">
                                <Button size={"big"} primary onClick={() =>
                                    window.open(`https://files.cryptolooper.com/CryptoLooper-mac-${latest}.dmg`)}>
                                    <Icon name="apple" /> CryptoLooper For Mac
                                </Button>
                            </Grid.Column>

                            <Grid.Column textAlign="center">
                                <Button size={"big"} primary onClick={() =>
                                    window.open(`https://files.cryptolooper.com/CryptoLooper-linux-${latest}.deb`)}>
                                    <Icon name="linux" /> CryptoLooper For Linux
                                </Button>
                            </Grid.Column>
                        </Grid>

                        <br />

                        <Container textAlign="center" text>
                            <div className="ui action input fluid">
                                <input ref="inpEmail" type="text" size="40" placeholder="your email" />
                                <Button className="right labeled icon" disabled={this.state.sent} onClick={() => {
                                    const email = this.refs.inpEmail.value;
                                    if (!validator.validate(email)) {
                                        alert("The e-mail doesn't seem to have correct format." + email);
                                        return;
                                    }

                                    const form = {
                                        u: '6c62018c45d6dbf7d585073b3',
                                        id: '9ec109c519',
                                        EMAIL: email
                                    };

                                    const formData = querystring.stringify(form);
                                    const contentLength = formData.length;

                                    const headerDict = {
                                        'Content-Length': contentLength,
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    };

                                    const requestInit = { mode: 'no-cors', headers: headerDict, method: 'POST', body: formData };
                                    fetch.default('https://cryptolooper.us18.list-manage.com/subscribe/post', requestInit);
                                    this.setState({ sent: true });
                                }}>
                                    <Icon name="mail" /> { this.state.sent ? "Request Sent" : "Request Token" }
                                </Button>
                            </div>
                        </Container>
                    </div>
                </Grid.Row>
                <GoHome/>
            </Grid>
        );
    }
}

export default Download;
