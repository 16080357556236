import React, { Component } from 'react';
import { Popup, Image } from 'semantic-ui-react';

// Icons //
//import Add from '../img/add.svg';
//import Down from '../img/down-arrow.svg';
//import Up from '../img/upload.svg';
import Question from '../img/question.svg';
//import SelectedFalse from '../img/success.svg';
//import SelectedTrue from '../img/interface.svg';
//import Refresh from '../img/rotate.svg';
//import Remove from '../img/cancel.svg';
//import Edit from '../img/gear.svg';
//import Trash from '../img/garbage.svg';
//import Save from '../img/save.svg';
//import To from '../img/to.svg';
import Next from '../img/next.svg';
import Back from '../img/back.svg';
//import More from '../img/more.svg';
//import Less from '../img/less.svg';
//import Stop from '../img/stop.svg';
//import Pause from '../img/pause.svg';
import Binance from '../img/binance_logo.svg';
//import Bittrex from '../img/bittrex_logo.svg';
//import Poloniex from '../img/poloniex_logo.svg';


class Icon extends Component {
    constructor(props) {
        super(props);

        this.icons = {
            //Add,
            //Refresh,
            //SelectedTrue,
            //SelectedFalse,
            //Down,
            //Up,
            Question,
            //Remove,
            //Edit,
            //Trash,
            //Save,
            //To,
            Next,
            Back,
            //More,
            //Less,
            //Stop,
            //Pause,
            Binance,
            //Bittrex,
            //Poloniex
        };
    }

    render() {
        return (
            <Popup trigger={<Image className={'Icon ' + this.props.style} src={this.icons[this.props.icon]} onClick={this.props.onClick} />} inverted  >
                <Popup.Content>
                    { this.props.children }
                </Popup.Content>
            </Popup>
        );
    }
}

export default Icon;
