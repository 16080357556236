import React, { Component } from 'react';
import { Grid, Message, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';
import '../App.css';
import GoHome from '../components/GoHome';

class AddAccount extends Component {
  render() {
    return (
      <Grid>
        <Link to='/'>
            <Icon style="go-back animate" icon="Back">
                Homepage
            </Icon>
        </Link>
        <Link to='/usemarket'>
            <Icon style="go-front animate" icon="Next">
                How To Use Market
            </Icon>
        </Link>
        <Grid.Row centered>
            <h1>How To Add Account</h1>
            <div className="box white normal">
                <Message>
                    <Header>
                        Disclaimer
                    </Header>
                    <p>
                        In this guide, we recommend to set your permissions properly to enable trading only. CryptoLooper never tries to withdraw money from your account.
                    </p>
                </Message>

                <p>In order to connect CryptoLooper to your exchange account(s), you have to provide API key and a secret. Here is how to find them in each of our supported exchanges.</p>

                <h3>Binance</h3>
                <p>
                    <ul>
                        <li>After login in, go to your account. It is located on the top right corner.</li>
                        <li>In API menu select “API setting”.</li>
                        <li>Create a name for your API key, it can be anything.</li>
                        <li>Click edit to select “Enable Trading”.</li>
                        <li>Select “show” to access the secret.</li>
                        <li>Copy both API key and the secret to CryptoLooper and you are all done.</li>
                    </ul>
                </p>
            </div>
        </Grid.Row>
          <GoHome />
      </Grid>
    );
  }
}

export default AddAccount;
